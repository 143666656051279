import React from 'react'

const SuccessInfoComponent = (props) => {
  return (

    <div>
      <div className='checkout '>
        <div className='checkout-container mt-5'>
          <div>
            <h4 className='pt-4 intro-text-header text-center mt-5'> Reactivation Successful </h4>
            <p className='pt-5 intro-text-body'> Your information has been successfully received and your account will be activated accordingly, Thank you.</p>
          </div>
        </div>
      </div>
      <div className='widget-footer'>
        <button
          className='btn btn-purple w-100 py-3 mb-5'
          style={{ background: `linear-gradient(${props.darkColor},${props.darkestColor})` }}
          onClick={props.dismiss} type='button'
        >
          Done
        </button>
      </div>
    </div>

  )
}
export default SuccessInfoComponent
