/* eslint-disable camelcase */
import React, { useState, useRef } from 'react'
// import StepProgressBar from 'react-step-progress'
import { useSelector } from 'react-redux'
import LightenDarkenColor from '../../../../util/changecolor'

export const NINNumberComponent = (props) => {
  const [number, setNumber] = useState(null)
  const [numberError, setNumberError] = useState('')
  const number_ref = useRef(number)
  number_ref.current = number

  const checkoutState = useSelector((state) => state.checkout)
  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  const numberContent = (
    <form action='' onSubmit={(e) => e.preventDefault()}>
      <div className='mb-5 mt-2'>
        <label>Enter National Identification Number</label>
        <input
          type='text'
          name='ni_number'
          value={number}
          className='form-control'
          onChange={(value) => setNumber(value.target.value)}
          placeholder='14567289268'
        />
        {numberError ? <p className='error-text p-0 m-0'> {numberError} </p> : null}
      </div>
    </form>
  )

  const onFormSubmit = () => {
    if (!number) {
      setNumberError('Number cannot be Empty')
    } else if (/^[0-9A-Za-z]{11,16}$/.test(number) === false) {
      setNumberError('Invalid NIN or vNIN')
    } else {
      setNumberError(null)
      var data = {
        number
      }
      props.updateSelfie(true, data)
    }
  }

  return (
    <div className='checkout'>
      <div className='checkout-container p-0'>
        <>
          <div className='header mb-3' />
          <h5>National Identity Number</h5>
          <p>Enter Your NIN in  the input box below</p>
          {numberContent}
          <div className=' widget-footer'>
            <button
              style={{ background: `linear-gradient(${darkColor},${darkestColor})` }}
              className='btn btn-purple w-100 py-3 mb-5'
              onClick={onFormSubmit}
              type='button'
            >
              Proceed
            </button>
          </div>
        </>
      </div>
    </div>
  )
}
