/* eslint-disable camelcase */
import React from 'react'
import { CountryComponent } from './channel'
import { connect } from 'react-redux'
import * as faceApi from 'face-api.js'

class IdentityVerificationComponent extends React.Component {
  state = {
    token: '',
    error_message: '',
    showAlert: false,
    data: {},
    country: null
  }

   componentDidMount = async () => {
     try {
       await faceApi.nets.tinyFaceDetector.loadFromUri('/models/')
       await faceApi.loadFaceExpressionModel('/models/')
     } catch (e) {
       this.log(e.name, e.message, e.stack)
     }
   }

  closeVerification = () => {
    // dispatch success event for parent element
    var event_response = {
      eventInstance: 'identity_verification_event',
      data: {
        status: 'failed',
        message: 'User Verification Cancelled'
      }
    }
    window.parent.postMessage(event_response, '*')
  }

  setCountry = (country) => {
    this.setState({ country })
  }

  render() {
    return (
      <div className='id-checker-widget-area'>
        <div className='col-md-8 col-lg-5 preview-area mx-auto mt-md-5 p-0'>
          {/* <button className='btn close-btn' onClick={this.closeVerification}>
            <i className='feather icon-x' />
          </button> */}
          <div className='card '>
            <div className='card-body'>
              <CountryComponent backAction={this.props.backAction} error={this.props.checkoutState.error} token={this.props.token} setCountry={this.setCountry} country={this.state.country} />
              <div className='widget-footer tagline'>
                <small> Powered by <span style={{ fontWeight: 600 }}> Prembly</span></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    checkoutState: state.checkout
  }
}
export default connect(mapStateToProps, null, null)(IdentityVerificationComponent)
